import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [0,2,6];

export const dictionary = {
		"/(public)/404": [50],
		"/(public)/auth/login": [~51,[14]],
		"/(public)/auth/logout": [~52,[14]],
		"/(public)/auth/reset-password": [~53,[14,15]],
		"/(public)/auth/reset-password/success": [54,[14,15]],
		"/(private)/(unverified)/auth/set-password": [~16,[2,3]],
		"/(public)/auth/set-password/success": [55,[14]],
		"/(private)/(verified)/(reporting)/dashboard": [23,[2,4,6]],
		"/(private)/(verified)/(operations)/operations": [17,[2,4,5]],
		"/(private)/(verified)/(operations)/operations/access-groups": [18,[2,4,5]],
		"/(private)/(verified)/(operations)/operations/access-groups/create": [20,[2,4,5]],
		"/(private)/(verified)/(operations)/operations/access-groups/[id]/edit": [19,[2,4,5]],
		"/(private)/(verified)/(operations)/operations/legal-entities": [21,[2,4,5]],
		"/(private)/(verified)/(operations)/operations/users": [22,[2,4,5]],
		"/(private)/(verified)/(reporting)/reporting": [24,[2,4,6]],
		"/(private)/(verified)/(reporting)/reporting/materiality": [25,[2,4,6,7]],
		"/(private)/(verified)/(reporting)/reporting/materiality/iros": [26,[2,4,6,7,8]],
		"/(private)/(verified)/(reporting)/reporting/materiality/iros/analysis": [27,[2,4,6,7,8]],
		"/(private)/(verified)/(reporting)/reporting/materiality/iros/create": [28,[2,4,6,7,8]],
		"/(private)/(verified)/(reporting)/reporting/materiality/iros/edit": [29,[2,4,6,7,8]],
		"/(private)/(verified)/(reporting)/reporting/materiality/iros/stakeholder-assessment": [30,[2,4,6,7,8]],
		"/(private)/(verified)/(reporting)/reporting/materiality/sources": [~31,[2,4,6,7]],
		"/(private)/(verified)/(reporting)/reporting/materiality/sub-subtopics": [32,[2,4,6,7,9]],
		"/(private)/(verified)/(reporting)/reporting/materiality/sub-subtopics/reasoning": [33,[2,4,6,7,9]],
		"/(private)/(verified)/(reporting)/reporting/materiality/sub-subtopics/selection": [34,[2,4,6,7,9]],
		"/(private)/(verified)/(reporting)/reporting/materiality/thresholds": [35,[2,4,6,7,10]],
		"/(private)/(verified)/(reporting)/reporting/materiality/thresholds/evaluation": [38,[2,4,6,7,10]],
		"/(private)/(verified)/(reporting)/reporting/materiality/thresholds/(types)/financial": [36,[2,4,6,7,10]],
		"/(private)/(verified)/(reporting)/reporting/materiality/thresholds/(types)/impact": [37,[2,4,6,7,10]],
		"/(private)/(verified)/(reporting)/reporting/sustainability-report": [39,[2,4,6]],
		"/(private)/(verified)/(reporting)/reporting/sustainability-report/data-points": [40,[2,4,6,11]],
		"/(private)/(verified)/(reporting)/reporting/sustainability-report/data-points/efrag-mapping": [41,[2,4,6,11]],
		"/(private)/(verified)/(reporting)/reporting/sustainability-report/data-points/exclusions": [42,[2,4,6,11]],
		"/(private)/(verified)/(reporting)/reporting/sustainability-report/data-points/rules": [43,[2,4,6,11]],
		"/(private)/(verified)/(reporting)/reporting/sustainability-report/data-points/summary": [44,[2,4,6,11]],
		"/(private)/(verified)/(reporting)/reporting/sustainability-report/disclosures": [45,[2,4,6,12]],
		"/(private)/(verified)/(reporting)/reporting/sustainability-report/disclosures/export": [46,[2,4,6,12]],
		"/(private)/(verified)/(reporting)/reporting/sustainability-report/disclosures/overview": [47,[2,4,6,12]],
		"/(private)/(verified)/(reporting)/settings": [48,[2,4,6,13]],
		"/(private)/(verified)/(reporting)/settings/reporting-context": [49,[2,4,6,13]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';